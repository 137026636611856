@import '../../scss/helpers/mixins';

.tooltip-box {
    position: relative;
    display: inline-block;
    margin-left: .3rem;

    .icon-btn {
        width: 1.4rem;
        height: 1.4rem;
        background: url('../../images/i_question_mark.png') no-repeat;
        background-size: 100% 100%;
        vertical-align: -8%;
        cursor: pointer;
    }

    .tooltip {
        @include basic-shadow-box();

        position: absolute;
        top: 180%;
        left: 50%;
        transform: translateX(-25%);
        display: inline-block;
        min-width: 30.4rem;
        box-sizing: border-box;
        padding: 1.5rem 1.7rem 1.7rem 1.7rem;
        line-height: 2.2rem;
        font-size: 1.4rem;
        color: #4c4c4c;
        box-shadow: 0 -.5rem 2.0rem hsl(0deg 0% 0% / 12%);
        z-index: 1;
        border: .05rem solid #eee;
        border-top: none;

        &.right-side {
            transform: translateX(-85%);

            &::before {
                left: 83%;
            }
        }

        &.list {
            display: flex;
            flex-direction: column;
            row-gap: 2rem;
            min-width: 37.2rem;
        }

        &::before {
            content: '▲';
            position: absolute;
            top: -1.2rem;
            left: 23%;
            font-size: 1.7rem;
            color: #fff;
        }

        .title {
            padding-bottom: .4rem;
            font-size: 1.5rem;
            font-weight: 700;
            line-height: 2.2rem;
        }

        .desc {
            font-size: 1.4rem;
        }
    }
}