@import '../../../scss/helpers/mixins';
@import '../../../scss/themes/colors.module';

.mna-info {
  height: 100%;
  padding-bottom: 4.0rem;

  form {
    width: 100%;
    height: 100%;
  }

  .temporary-save-btn {
    @include gray-border-btn();
  }

  .save-btn {
    @include primary-btn-design(1.0rem 1.9rem);

    display: flex;
    align-items: center;
    column-gap: 0.6rem;
    line-height: 2rem;
    font-size: 1.3rem;
    font-weight: 700;

    i {
      display: inline-block;
      width: 1.4rem;
      height: 1.4rem;
      background-image: url('../../../images/save_chk_btn.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }

  .title-line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: .1rem solid #eee;
    padding-top: 4.0rem;
    padding-bottom: 0.5rem;
    margin-bottom: 2.7rem;

    .title {
      height: 4.9rem;
      display: flex;
      align-items: center;
      column-gap: 0.8rem;
      font-size: 2.0rem;
      font-weight: 700;

      .status-btn {
        padding: 0.6rem 0.8rem;
        font-size: 1.4rem;
        color: $color-main;

        i {
          display: inline-block;
          width: 1.8rem;
          height: 1.8rem;
          background: url('../../../images/arrow3_right.png') no-repeat;
          background-size: 100% 100%;
          margin-left: .3rem;
          vertical-align: -20%;
        }
      }
    }

    .btn-area {
      display: flex;
      column-gap: 1.2rem;
    }

    .print-btn {
      display: flex;
      align-items: center;
      column-gap: 0.6rem;
      line-height: 2rem;
      font-size: 1.3rem;
      padding: 1rem 1.9rem;
      border-radius: 0.6rem;
      background-color: #f9f9f9;

      &:active {
        background-color: #f5f5f5;
      }

      @media (hover: hover) {
        &:hover {
          background-color: #f5f5f5;
        }
      }

      i {
        display: inline-block;
        width: 1.9rem;
        height: 1.9rem;
        background: url('../../../images/i_print.png') no-repeat;
        background-size: 100% 100%;
      }
    }
  }

  .content {
    height: 100%;

    .company-type {
      display: flex;
      align-items: center;
      column-gap: 1.6rem;

      .type-title {
        height: 2.7rem;
        padding-right: 1.6rem;
        font-size: 1.7rem;
        font-weight: 700;
      }

      .radio-btn {
        @include main-radio-box();

        display: flex;
        align-items: center;
        height: 2.0rem;
        padding-bottom: 1.1rem;
        font-size: 1.5rem;

        input[type='radio']:before {
          width: 1.8rem;
          height: 1.8rem;
        }
      }
    }

    .company-type-form {

      i.noti-num {
        vertical-align: top;
        font-size: 1rem;
        color: #5c67ff;
      }

      .noti {
        padding-top: 1.5rem;
        margin-bottom: -1rem;
        font-size: 1.2rem;
        color: #808080;
      }

      .noti2 {
        padding-top: 1.5rem;
        margin-bottom: -1rem;
        font-size: 1.4rem;
      }

      .sub-title {
        padding-top: 4rem;
        padding-bottom: 1.6rem;
        font-size: 1.5rem;
        font-weight: 500;

        i.error-message {
          font-size: 1.2rem;
          color: #f53f50;
        }

        &.gap2 {
          padding-bottom: 3.2rem;
        }

        span {
          font-weight: 400;
          color: #808080;
        }
      }

      .divide-line-info {
        margin-top: 4.8rem;
        border-top: 0.1rem solid #eee;
        padding-top: 1.6rem;
        font-size: 1.6rem;
        line-height: 2rem;
      }

      .separate-label {
        padding-top: 4rem;
        font-size: 1.5rem;
        font-weight: 700;
      }

      .select-box {
        position: relative;

        &.error > div {
          border: 1px solid #f53f50;
          box-sizing: border-box;
        }

        input {
          cursor: pointer;

          &:read-only {
            color: #000;
          }
        }

        &::after {
          position: absolute;
          right: 1.8rem;
          top: 50%;
          transform: translateY(-50%);
          content: "";
          display: inline-block;
          width: 1.5rem;
          height: 1.5rem;
          background-image: url('../../../images/arrow_down.png');
          background-repeat: no-repeat;
          background-size: 100% 100%;
        }
      }

      .flex-row-box {
        position: relative;
        display: flex;
        text-align: center;
        font-size: 1.4rem;

        .flex-column-box {
          flex: 1;
          display: flex;
          flex-direction: column;
          row-gap: 1.6rem;

          .input-box.error > div {
            border: 1px solid #f53f50;
          }

          .no-input-box {
            line-height: 2rem;
            padding: 1.3rem;
          }

          p:first-child {
            color: #4c4c4c;

            &.opacity-0 {
              opacity: 0;
            }
          }

          &:first-child {
            text-align: left;
            color: #4c4c4c;
          }
        }
      }

      //.text-area {
      //  @include basic-input-box();
      //
      //  resize: none;
      //  outline: none;
      //  width: 100%;
      //  height: 15rem;
      //  box-sizing: border-box;
      //  padding: 1.3rem 1.8rem;
      //  text-align: left;
      //
      //  &.ver2 {
      //    height: 6.7rem;
      //  }
      //
      //  //input {
      //  //  height: 15rem;
      //  //  padding: 1.3rem 1.8rem;
      //  //  box-sizing: border-box;
      //  //}
      //}

      .text-area-box {
        position: relative;
        display: flex;
        flex-direction: column;
        row-gap: 1.6rem;
        padding-top: 4rem;

        label {
          font-size: 1.5rem;
          font-weight: 500;



          &.inner-title {
            font-size: 1.4rem;
            font-weight: 400;
          }

          .plus_info {
            span {
              font-weight: 400;
              color: #808080;
            }
          }
        }

        textarea {
          height: auto;
          font-size: 1.4rem;
          overflow: hidden;
          border: none;
          padding: 1.3rem 1.8rem;
          background-color: #f9f9f9;
          border-radius: 0.8rem;
          resize: none;
          letter-spacing: -0.7px;
          box-sizing: border-box;
          outline: none;

          &.error {
            border: 1px solid #f53f50;
          }
        }
      }

      .btn-area {
        display: flex;
        align-items: center;
        column-gap: 1.2rem;
        padding: 4.8rem 0 10rem;
      }
    }

    .connect-channel-talk {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      row-gap: 1.6rem;
      height: calc(100% - 13rem);
      text-align: center;

      .img {
        width: 16.7rem;
      }

      .title {
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 2rem;

        span {
          color: $color-main;
          font-weight: 700;
        }
      }

      .desc {
        font-size: 1.4rem;
        line-height: 2rem;
        color: #666;

        span {
          color: $color-main;
        }
      }

      .channel-talk-btn {
        @include background-color-hover($color-main, $color-main-hover);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 14.2rem;
        height: 4rem;
        border-radius: 0.6rem;
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 2rem;
        color: #fff;
      }
    }
  }
}